import React from "react"
import { Button } from "reactstrap"

const courses = [
    {
        name: 'JEE- II',
        title: 'Entrance Exam',
        description: 'The joint entrance examination (JEE Main) comprises of two papers. The paper 1 is conducted for admission to undergraduate engineering programs'
    },
    {
        name: 'NATA',
        title: 'Entrance Exam',
        description: 'The joint entrance examination (JEE Main) comprises of two papers. The paper 1 is conducted for admission to undergraduate engineering programs'
    },
    {
        name: 'NID DAT',
        title: 'Entrance Exam',
        description: 'The joint entrance examination (JEE Main) comprises of two papers. The paper 1 is conducted for admission to undergraduate engineering programs'
    }
]

export const TestSeries = () => {
    return (<>
        <div className="container-fluid bg-orange">
            <div className="container">
                <div className="row">
                    <div className="col align-self-center justify-self-center ms-md-5 order-md-last mt-5 mt-md-0 text-light text-center">
                        <h2 className="font-800 text-light">Entrance Exam</h2>
                        <div className="mt-3">Entrance examination is the mode for getting admission into various undergraduate, post-graduate and profession degree courses. Basically entrance examination is common at higher level of education which is conducted by educational institutes and colleges.</div>
                    </div>
                    <div className="col-auto">
                        <img className="img-fluid" src="/images/exam-page-infpgraphic.png" />
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid bg-light">
            <div className="container py-5">
                <div className="row">
                    {courses.map((course, i) => (
                        <div className="col-12 col-md-4 mb-3 text-center" key={i}>
                            <div className="bg-white border p-3">
                                <h1 className="text-purple">{course.name}</h1>
                                <div><b>{course.title}</b></div>
                                <div className="mt-3">{course.description}</div>
                                <div className="mt-3">
                                    <Button color="orange">KNOW MORE EXAM</Button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </>)
}