export const Privacy = () => (
    <div className="container py-5">
        <div className="text-center mb-5"><h3>Privacy Policy</h3></div>

        <b>You certainly understand and concur that:</b>
        <div className="mb-3 mt-2">
            <b>Ruddhi Eduserv LLP (“RUDDHI” or “We” or “Us” or “Our”)</b> has created this Privacy
            Policy in order to demonstrate own organization commitment to privacy. Throughout the
            cyberspace we want to contribute towards providing a safe and secure digital
            environment, safe information gathering and dissemination practices for all our sites.
        </div>
        <b>1. Applicability</b>
        <div className="mb-3 mt-2">
            This Policy applies to <b>www.ruddhi.co.in</b> (“Website”) and mobile application of <b>RUDDHI</b> but not to any other companies or organizations&#39; websites or mobile applications to
            which we link.
            Information Gathering and Usage
        </div>
        <b>2. Registration / Information</b>

        <div className="mb-3 mt-2">
            When you sign up with Website or App, RUDDHI asks you for personal information. We
            may combine the information you submit under your account with information from other
            services / third parties in order to provide you with a better experience and to improve
            the quality of our services. For certain services, we may give you the opportunity
            to opt out of combining such information.
            You may provide us with certain information such as your Name, E-mail address,
            Correspondence address when registering for certain services such as Online
            Registration / Submit Educational details and background etc. This information will
            primarily be used for the purpose of providing personalization and verification.
        </div>
        <b>3. Cookies</b>
        <div className="mb-3 mt-2">
            A cookie is a small data file that Websites or App write to your hard drive when you visit
            them. A cookie file can contain information such as a User ID that the site uses to track
            the pages you have visited. A cookie can contain information you supply yourself. A
            cookie can&#39;t read data of your hard disk or read cookie files created by other sites. Our
            Website/App uses cookies to track user traffic patterns and for the personalization
            feature.
        </div>

        <b>4. User communications</b>
        <div className="mb-3 mt-2">
            When you send email or other communications to our Website or App, we may retain
            those communications in order to process your inquiries, respond to your requests and
            improve our services. When you send and receive SMS messages to or from one of our
            services that provides SMS functionality, we may collect and maintain information
            associated with those messages, such as the phone number, the content of the message, and the date and time of the transaction. We may use your email address to
            communicate with you about our services.
        </div>

        <b>5. Log information</b>
        <div className="mb-3 mt-2">
            When you access our Website or App services via a browser or, mobile application or
            other client, our servers automatically record certain information. These server logs may
            include information such as your web request, your interaction with a service, Internet
            Protocol address, browser type, browser language, the date and time of your request
            and one or more cookies that may uniquely identify your browser or your account.
        </div>

        <b>6. Electronic Newsletter/E-mail</b>
        <div className="mb-3 mt-2">
            RUDDHI may circulate free electronic newsletter to its users, who voluntarily subscribe
            to opt the same.  Users may unsubscribe themselves from availing this facility by
            RUDDHI by using the link provided in every newsletter.
        </div>

        <b>7. Confidentiality &amp; Security</b>
        <div className="mb-3 mt-2">
            This Privacy Policy applies to <b>RUDDHI</b> Website and mobile application. <b>RUDDHI</b> do not
            exercise control over the sites displayed as search results, sites that include other
            applications, products or services, or links from within our various services. Personal
            information that you provide to other sites may be sent to our Website and/or App in
            order to deliver the service. We process such information under this Privacy Policy.
            We are concerned about safeguarding the confidentiality of your Information. We
            provide physical, electronic, and procedural safeguards to protect Information we
            process and maintain. For example, we limit access to this Information to authorized
            employees only who need to know that information in order to operate, develop or
            improve our Services/products/Website. Please be aware that, although we endeavor to
            provide reasonable security for information we process and maintain, no security
            system can prevent all potential security breaches.
        </div>

        <b>8. Alerts</b>
        <div className="mb-3 mt-2">
            We may alert you by email or phone (through SMS/call) to inform you about new service
            offerings of <b>RUDDHI</b> and other information which we feel might be useful for you.
        </div>

        <b>9. Feedback</b>
        <div className="mb-3 mt-2">
            <b>RUDDHI</b>’s Feedback Form requires personal information of users like contact number,
            name and e-mail address and demographic information like their zip code, age etc. for
            any feedback by the users pertaining to the services rendered by <b>RUDDHI</b>.
        </div>

        <b>10. Further Improvement</b>

        <div className="mb-3 mt-2">
            Apart from the above, we may use user information to provide, maintain, protect,
            Improve and develop our services.
        </div>

        <b>11. Your Consent</b>
        <div className="mb-3 mt-2">
            We believe that, every user of our Services/products/Website must be in a position to
            provide an informed consent prior to providing any Information required for the use of
            the Services/products/Website. By registering with us, you are expressly consenting to
            our collection, processing, storing, disclosing and handling of your information as set
            forth in this Policy now and as amended by us. Processing, your information in any way,
            including, but not limited to, collecting, storing, deleting, using, combining, sharing,
            transferring and disclosing information, all of which activities will take place in India. If
            you reside outside India your information will be transferred, processed and stored in
            accordance with the applicable data protection laws of India.
        </div>
        <b>12. Queries regarding the Website or APP</b>
        <div className="mb-3 mt-2">
            If you have any query regarding this Privacy Policy, you may contact at
            admin@ruddhi.co.in.
        </div>
    </div>
)