import React from "react"

export const LiveClass = () => {
    return (<>
        <div className="container-fluid bg-light">
            <div className="container py-5">
                <div className="row">
                    <div className="col-12 mb-3">
                        <h2 className="text-center text-purple">LIVE CLASS</h2>
                    </div>
                    <div className="col-12 col-md-6 m-auto">
                        <iframe width="100%" height="350" src="https://www.youtube.com/embed/smuykSYGNlY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
