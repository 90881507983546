import React from "react"
import { Button } from "reactstrap"
import { Link } from 'react-router-dom'

const careers = [
    {
        name: 'FACULTY',
        description: 'A PERSON WHO WILLING HAS CARRER IN ACADEMICS CAN JOIN OUR TEAM WITH OR WIHOUT EXPERIENCE. HE/SHE SHOULD HOLD A DEGREE IN FOLLOWING AREAS:',
        list: ['BACHELOR OF DESIGN (B.DES), MASTER OF DESIGN (M.DES)', 'BACHELOR OF ARCHITECTURE(B.ARCH), MASTER OF ARCHITECTURE(M.ARCH)', 'BACHELOR OF FINE ARTS (B.F.A), MASTER OF FINE ARTS (M.F.A)']
    },
    {
        name: 'FRANCHISE',
        description: 'A PERSON WHO WILLING TO HAVE A FRANCHISE SHOULD OWN THE FOLLOWING',
        list: ['A SPACE OR A CLASSROOM FOR MINIMUM 20 STUDENTS.', 'QUALIFIED FACULTY MEMBERS.']
    },
    {
        name: 'INDIVIDUAL CANVASSER',
        description: 'Individuals who have contacts in educational fields and who are willing to represent the classes to various schools, academies and classes',
        list: []
    },
    {
        name: 'OPPORTUNITY FOR STUDENTS',
        description: 'STUDENTS WHO ARE WILLING TO TAKE SEMINARS OR WORKSHOPS CAN CONTACT US. WORK EXPERIENCE WILL NOT BE CONSIDERED JUST THE REPUTED INSTITUTION WHERE YOU STUDY WILL ONLY BE THE CRITERIA.',
        list: ['SKILLED CRAFTSMANS AS WELL AS LOCAL ARTISTSANS WHO IS WILLING TO CONDUCT WORSHOP RELVANT TO THE CURRECULUM OF ARCHITECTURE AND DESIGN FIELDS.']
    },
]

export const Careers = () => {
    return (<>
        <div className="container-fluid bg-orange">
            <div className="container">
                <div className="row">
                    <div className="col align-self-center justify-self-center ms-md-5 order-md-last mt-5 mt-md-0 text-light text-center">
                        <h2 className="font-800 text-light">JOIN OUR TEAM</h2>
                        <div className="mt-3">JOIN OUR TEAM AND HELP TO BUILD THE INDIA’S BEST PLATFORM FOR STUDENTS WHO ARE WILLING TO BE AT THE BEST COLLEGES/INSTITUTES IN INDIA FOR DESIGN, FINE ARTS AND ARCHITECTURE COURSES.</div>
                    </div>
                    <div className="col-auto">
                        <img className="img-fluid" src="/images/exam-page-infpgraphic.png" />
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid bg-light">
            <div className="container py-5">
                <div className="row">
                    {careers.map((item, i) => (
                        <div className="col-12 col-md-3 mb-3 text-center" key={i}>
                            <div className="bg-white border p-3 h-100 d-flex flex-column">
                                <h3 className="text-purple mb-3">{item.name}</h3>
                                <div className="mt-auto">
                                    <Link to={'/career/' + item.name}>
                                        <Button color="orange">KNOW MORE</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </>)
}

export const Career = ({ match }) => {
    const item = careers.find(i => i.name == match.params.name)
    return (<>
        <div className="container-fluid bg-orange">
            <div className="container">
                <div className="row">
                    <div className="col align-self-center justify-self-center ms-md-5 order-md-last mt-5 mt-md-0 text-light text-center">
                        <h2 className="font-800 text-light">JOIN OUR TEAM</h2>
                        <div className="mt-3">JOIN OUR TEAM AND HELP TO BUILD THE INDIA’S BEST PLATFORM FOR STUDENTS WHO ARE WILLING TO BE AT THE BEST COLLEGES/INSTITUTES IN INDIA FOR DESIGN, FINE ARTS AND ARCHITECTURE COURSES.</div>
                    </div>
                    <div className="col-auto">
                        <img className="img-fluid" src="/images/exam-page-infpgraphic.png" />
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid bg-light">
            <div className="container py-5">
                <div className="row">
                    <div className="col-12 text-capitalized">
                        <div>{item?.description}</div>
                        <ol>
                            {item?.list?.map((item, i) => (<li key={i}>{item}</li>))}
                        </ol>
                        <div className="mt-5">
                            <Button color="orange" className="me-3" onClick={() => window.history.back()}>Back</Button>
                            <Button color="orange">Apply Now</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}