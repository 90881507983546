import React, { useState } from 'react'
import { Slider } from "components/slider";
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody } from "reactstrap";
import { RegistrationForm } from 'components/registration-form';

const slides = [
    {
        src: '/images/slider1.jpg',
        altText: '',
        caption: ''
    },
    {
        src: '/images/slider2.jpg',
        altText: '',
        caption: ''
    },
    {
        src: '/images/slider3.jpg',
        altText: '',
        caption: ''
    },
    {
        src: '/images/slider4.jpg',
        altText: '',
        caption: ''
    },
    {
        src: '/images/slider5.jpg',
        altText: '',
        caption: ''
    },
    {
        src: '/images/slider6.jpg',
        altText: '',
        caption: ''
    }
];
const results = [
    { name: 'Mugdha Tarte', course: 'design', year: 2021 },
    { name: 'Ritika Salve', course: 'design', year: 2021 },
    { name: 'Sharayu Jadhav', course: 'architecture', year: 2021 },
    { name: 'Aaditya Khairnar', course: 'architecture', year: 2021 },
    { name: 'Srushti Padalkar', course: 'architecture', year: 2021 },
    { name: 'Sakshi Pathak', course: 'architecture', year: 2021 },
    { name: 'Khushi Gadewar', course: 'architecture', year: 2021 },
    { name: 'Kaushik Kabra', course: 'architecture', year: 2021 },
    { name: 'Siya Deokar', course: 'architecture', year: 2021 },
    { name: 'Sai Navale', course: 'architecture', year: 2021 },
    { name: 'Bhakti Soni', course: 'design', year: 2021 },
    { name: 'Manasi Landge', course: 'architecture', year: 2021 },
    { name: 'Tanishka Rajpal', course: 'architecture', year: 2021 },
    { name: 'Shruti Chhajed', course: 'architecture', year: 2021 },
]
const classes = [
    { name: 'online class', img: '/images/onine-class-icon.png' },
    { name: 'offline class', img: '/images/offline-class-icon.png' },
    { name: 'study material', img: '/images/study-material-icon.png' },
    { name: 'online test', img: '/images/online-test-icon.png' },
    { name: 'exp, faculty', img: '/images/exp-faculty-icon.png' },
    { name: 'more practicle', img: '/images/more-practice-icon.png' },
]
const feedbacks = [
    // { name: 'APURVA PRABHUNE', img: 'APURVA PRABHUNE', place: 'NID, AHEMADABAD', comment: 'From being completely clueless about designing to strive to give my best, Sampada ma’am inspired me to aim high and achieve my dream. At every step of the exam, she stood by me and made me believe that I can get into the best design institute in India. It would have never been possible to get into NID without her guidance. ' },
    // { name: 'SRUSHTI PADALKAR', img: 'Srushti Padalkar', place: '', comment: 'Taking admission to this class was my perfect decision. Each concept is taught clearly and perfectly. The teachers give special attention to solve our doubts. I could see the improvement in my ideas and drawing neatness. I thank Sampada mam for guiding me in every situation and motivate me to work hard and think creatively. I feel fortunate and proud to be a student of this arcade. ' },
    // { name: 'SHASHANK PATIL', img: 'shashank', place: 'SPA VIJAYWADA', comment: 'I was in dilemma while choosing the appropriate field for me before joining RUDDHI ACADEMY. Because of Sampada Ma’am’s unique method and way of teaching. I developed a keen interest in Architecture and realized that I chose the right path. Learning under her guidance was fun and exciting. We were always excited to attend the class. ' },
    // {
    //     name: 'APURVA CHAVAN', img: 'apurva chavan', place: 'SPA VIJAYAWADA', comment: `	I just moved to another city for studies, and there I came across Ruddhi Academy. As a new girl in the city. I was nervous, but Sampada ma’am showed the right direction and guided me towards getting into a govt. College for Architecture. 
    // Sampada ma'am guided me through my studies and helped a lot in choosing the correct college and securing my future.` },
    // { name: 'OM TAWANI', img: 'om tawani', place: 'OM TAWANI', comment: 'I started my journey from a Dot. Joined by many other dots to form a vast Shape. This is all possible due to the Flawless Guidance, Regular Consultancy, and highly effective study material Sampada Ma’am & Sir. She always stood with me and due to this, I’m now into a government-aided and among the top 5 institutes in India, SPA-Vijayawada.' },
    // { name: 'RUSHIKESH LONDHE', img: 'Rushikesh Londhe', place: 'JJ COLLEGE OF ARCHITECTURE, MUMBAI', comment: '	It was a sudden decision to choose architecture, and I doubted it for many days until I started coaching Ruddhi Academy. The technique of teaching is so splendid and interactive that one learns complicated concepts very easily. Also, the environment is very productive over here. Be sides academics, I learned values, and the time I spent here helped me in my overall development. ' },
    // { name: 'PRASAD KALE', img: 'prasad kale', place: 'SPA BHOPAL', comment: '	JEE mains paper 2 is unlike another competitive exam; it doesn’t have any particular syllabus. That’s why the appropriate teaching is essential, and that comes from good experience; that’s where this class is good at. We all always got proper guidance, a helpful environment, good quality of education. And now also, whenever we need, we all are still being guided!' },
    // { name: 'SAHIL DEBRA', img: 'sahil debra', place: 'KAMALA RAHEJA, MUMBAI', comment: '	I prepared for NATA and design at Ruddhi Classes. The course is so perfectly designed to improve the student’s creative mindset and give him technical knowledge. Timely motivation by mam is what gets you through. Also, to mention ma’am covered many small details that any other classes don’t have and are helpful in degree course. ' },
    // { name: 'AMRUTA DEODA', img: 'Amruta Deoda', place: 'UID AHEMADABAD', comment: '	Sampada mam is not only a great teacher but also an inspiration. Many teachers come and go, but some teachers like her leave a beautiful impact on our lives. I still remember all her teachings, the result of which is who I am today. Thanks for making learning so much fun and for explaining all the concepts so well. I would recommend every design student to join Ruddhi Academy without any doubt. ' },
    // { name: 'SANIYA AGARWAL', img: 'saniya agarwal', place: 'KAMALA RAHEJA, MUMBAI', comment: '	I JOINED Ruddhi Academy in 12th standard. I started preparing for designing and architecture and, with Ma’am’s guidance, could crack both the exams and then decided to pursue Architecture. Ma’am and Sir motivated me, which helped me boost my confidence and thus get into one of the best institutes. ' },
    // { name: 'MRUNMAYEE KHILLARE', img: 'mrunmayee ', place: 'NIFT BHOPAL', comment: '	After joining Ruddhi Classes, two main things brought into my account are-guidance and regularity. I like the way of teaching. In case of any query, the approach towards sir and ma’am is not a difficult task. The turning point is a stepping stone towards success, and joining this class was one of my best decisions. ' },
    // { name: 'ANUSHKA BHAGWAT', img: 'Anushka Bhagwat', place: 'NIFT JODHPUR', comment: 'The Ruddhi Academy has helped me gain access to national design schools. Every student’s foundation is built by Sampada ma’am, who made us work on our sketching skills, creative ability, and, most significantly, general knowledge. Here, all of the students and teachers are like a family. If you are interested in design and have read my review. You must visit Ruddhi Academy. ' },
    // { name: 'VAIDEHI TUNGE', img: 'vaidehi', place: 'MIT, PUNE', comment: 'Ma\'am gave personal attention to every student and ensured that they were improving their skills and doing better every day. The tests conducted gave us an idea of how exams are held, and we got a good understanding of the pattern and the question asked. Overall, I had a great experience in Ruddhi. I will recommend for the students who are willing to apply for b.arch/design entrances. ' },
    // { name: 'MRUNMAYEE JOSHI', img: 'mrunmayee joshi', place: 'VNIT, NAGPUR', comment: 'In a journey of 2 years, while preparing for JEE/NATA. Ma’am & sir kept us all Motivated all the time. They have a unique teaching method with regular doubt solving: Ma’am gave Personal attention to us. Along with the JEE mains classes. Classes for preparing purely for board exams are also available. Overall, I had a good learning experience at Ruddhi Academy. ' },
    // {
    //     name: 'NANDINI DHOOT', img: 'Namdini dhoot', place: 'NIFT MUMBAI', comment: `	My journey of two years was full of ups and downs, but throughout Sampada Ma’am had my back. 
    // From helping me choose my career streams to guiding me into getting into NIFT, she has had my back and still continues too. The classes prepared me both with my skills and also mentally for the hard work and dedication that needs to be put to achieve the goals.    
    //     Sampada ma'am has guided me at every step which surely helped me get into NIFT, Mumbai.` },
    // { name: 'SAURABH YEOLE', img: 'saurabh yeole', place: 'VNIT NAGPUR', comment: '	Sampada ma\'am has got a unique way of making a sculpture out of a boulder. With the guidance of Takalkar sir and ma’am. I have seen developing myself into a person who is entirely different than before. They guide us not only till the exam but throughout our journey into the field and life. I am lucky and happy to have them as my mentor. My GURU.' },
    // { name: 'NIDHI KULKARNI', img: 'nidhi', place: 'PES UNIVERSITY, BANGLORE', comment: '	I Joined Ruddhi Classes right after my 10th. Apart from NATA preparation, Sampada ma’am would also give insights into the field, making learning more enjoyable. She also guided me during the application processes and also advised me during my college years. Five years down the line, here I am, just graduated from PES University, Bengaluru. ' },
    // { name: 'SHUBHANKAR JAHAGIRDAR', img: 'shubhankar', place: 'VNIT, NAGPUR', comment: 'The journey of one year with Sampada ma\'am and Ruddhi Classes made me confident about my goals. Close attention and one-to-one interaction cleared all the doubts and freed the path to VNIT, Nagpur. To achieve anything, you don’t need only hard work and proper guidance, and I’m grateful that I got one from Sampada ma’am, Takalkar Sir, and whole Ruddhi Classes. ' },
    // { name: 'NAMRATA DHOBEKAR', img: 'Namrata D.', place: 'SPA VIJAYAWADA', comment: '	I had joined classes just two months before JEE exam. Sampada mam showed faith in me and worked on my skills daily. As a result. I got admitted to SPA Vijayawada. Her guidance and advice have always worked best for me. I can’t suggest any better option than Ruddhi Academy to pursue design and architecture career paths. ' },
    // { name: 'ABHISHEK BURKULEY', img: 'abhishekh', place: 'VNIT, NAGPUR', comment: '	I had enrolled in a crash course to prepare for the architectural entrance exam. Ma’am was the one who first exposed me to architecture and its various aspects. I cracked the exam thanks to a clear understanding of the fundamentals, timely examinations, and consistent enthusiasm throughout the process. Furthermore, Ma’am and Sir were ready for assistance at all times, even in late hours.' },
    // { name: 'Satish Patil', img: 'avatar', place: '', comment: 'My son Shashank Patil completed his entrance preparations at Ruddhi Coaching. They have excellent teaching methods. They also provide excellent career guidance. Now my son is in his Second Year pursuing architecture at SPAV. An overall unique and memorable experience. Coaching in Ruddhi creates a firm foundation for the upcoming five years. I will surely recommend Ruddhi to my friends and family.' },
    // { name: 'Balkrishna Londhe', img: 'Balkrishna Londhe', place: '', comment: 'The teaching techniques are so interactive, and also the environment is very productive. Besides academics, mam is very supportive. She inspires the students and also guides them skilfully. Everything is amicable and proper. My son Rushikesh is now pursuing B. Arch. At Sir J.J. College of Architecture. I will recommend this coaching to all my fellow family members and friends. Thank You' },
    // { name: 'Sapna Agrawal', img: 'avatar', place: '', comment: 'I am a doctor by profession. It is a little tricky to guide different faculty, especially our children. But in that thing, Ruddhi Coaching Class provided me with proper guidance. It is the best class for the NATA entrance exam. Teachers constantly update themselves with current activities and help children a lot. Thank you to mam and all coaching team.' },
    // { name: 'Narendra Kabra', img: 'Narendra Kabra', place: '', comment: 'I am a business person. My son Kaushik Kabra is in his First Year studying B. Arch. At SPA Vijayawada. Two years of experience teaching and guidance at Ruddhi Coaching were excellent. Sampada mam and Takalkar sir guided the students very well. Their devotion toward every child is palpable. I will recommend this coaching to all who are looking for proper guidance. Thank you for guiding my son and me.' },
    // { name: 'Abhay Prabhune', img: 'Abhay Prabhune', place: '', comment: "My daughter Apoorva studied at Ruddhi Academy for two and half years. Sampada's mam guided her throughout the whole journey. She has not only taught the concepts but also inspired the students with unique ideas and stood with them till the last moment. My daughter has got the best mentorship here. I appreciate your support and keep it up." },
    // { name: 'Kashish Rajpal', img: 'Kashish Rajpal', place: '', comment: 'My daughter is currently studying in the First Year of B. Arch. At VNIT Nagpur. Experience during the entrance exam preparation at Ruddhi academy was excellent. Sampada mam and all the other teachers are very helpful, and their teaching techniques are unique. My child was delighted under the guidance of Sampada mam. She solves every doubt. They also held regular tests and presentations. The atmosphere of the class is filled with support and proper guidance. Overall amazing experience. Thank you, going well, and keep going on.' },
]

const data = new Array(25).fill(0).map((_, i) => `RESULT (${i + 1}).png`)

export const Home = () => {
    const [modalOpen, setModalOpen] = useState(false);

    const toggleModal = () => setModalOpen(!modalOpen)

    return (
        <>
            <Slider items={slides} />
            <div className="container mt-4">
                <div className="row">
                    {/* <div className='col-12 text-center mb-3'> <img src='/images/new.png' height={25} /><span className='notification-alert ms-2'>New branch is open in Pune!</span></div> */}
                    <div className="col-12 col-md-4 pt-4">
                        <img src='/images/welcome-image.png' className="img-fluid" />
                    </div>
                    <div className="col-12 col-md pt-4">
                        <div><b>A LITTLE ABOUT RUDDHI</b></div>
                        <h2 className="text-purple font-300 mb-0 mt-3">ARCHITECTURE & FINE ART</h2>
                        <h2 className="text-purple font-700">DESIGN INSTITUTE</h2>
                        <div className="mt-5">
                            We, Ruddhi Eduserv LLP are pioneers in Architecture & Design Coaching. We provide state of the art coaching facilities.<br /><br />
                            Founded in 2014, we have an excellent track record of 800 plus candidates successfully placed in reputed Architecture and Design Institute across India, namely NIT, SPAs, NID, NIFT, IIT, etc.
                        </div>
                    </div>
                </div>
            </div>

            {/* Courses */}
            <div className="container-fluid bg-building bg-light mt-5 pb-4 pt-5">
                <div className="container mt-4 mb-5">
                    <div className="row">
                        <div className="col-12 text-purple text-center mb-4">
                            <div>WHAT WE SERVE</div>
                            <h2><span className="font-300">OUR</span> <span className="font-700">SPECIALIZATION</span></h2>
                        </div>
                        <div className="col-12 col-md-4 mb-3">
                            <div className="bg-white p-3 text-center rounded h-100 d-flex flex-column">
                                <div className="text-center">
                                    <img src='/images/architecture-iocn.png' height={120} />
                                </div>
                                <div className="mt-3">
                                    <h3 className="font-700 mb-0">ARCHITECTURE</h3>
                                    <h3 className="font-300">ENTRANCE</h3>
                                </div>
                                <div className="mt-3">We prepare students for Architecture entrance exams such as JEE Paper II and NATA. Through these 2 exams, students can get admission to Bachelor of Architecture course to various government and private institutes respectively. </div>
                                <Link to='/courses/ARCHITECTURE' className="mt-auto">
                                    <Button color="orange" className="mt-3" >VIEW DETAILS</Button>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-3">
                            <div className="bg-orange p-3 text-center rounded h-100 d-flex flex-column">
                                <div className="text-center">
                                    <img src='/images/design-icon.png' height={120} />
                                </div>
                                <div className="mt-3">
                                    <h3 className="font-700 mb-0">DESIGN</h3>
                                    <h3 className="font-300">ENTRANCE</h3>
                                </div>
                                <div className="mt-3">Various design entrance exams offers admission to different government as well as private institutes all over the country. We prepare students for NID, NIFT, UCEED, UID as well as many design entrance exams. </div>
                                <Link to='/courses/DESIGN' className="mt-auto">
                                    <Button color="purple" className="mt-3 border-white" >VIEW DETAILS</Button>
                                </Link>                            </div>
                        </div>
                        <div className="col-12 col-md-4 mb-3">
                            <div className="bg-white p-3 text-center rounded h-100 d-flex flex-column">
                                <div className="text-center">
                                    <img src='/images/fineart-icon.png' height={120} />
                                </div>
                                <div className="mt-3">
                                    <h3 className="font-700 mb-0">FINE ARTS</h3>
                                    <h3 className="font-300">ENTRANCE</h3>
                                </div>
                                <div className="mt-3">We prepare students for State level entrance exam MH-AACET for admission to fine arts colleges. </div>
                                <Link to='/courses/FINE ARTS' className="mt-auto">
                                    <Button color="orange" className="mt-3" >VIEW DETAILS</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Entrance Exams */}
            <div className="container mt-5 pb-5">
                <div className="row">
                    <div className="col-12 text-center mb-4">
                        <h2 className="text-purple"><span className="font-300">ENTRANCE EXAMS</span></h2>
                    </div>
                    {['NATA', 'JEE PAPER II', 'NID UG', 'NID PG', 'NIFT', 'UCEED', 'CEED', 'NIG (UG/PG)', 'NIFT (UG/PG)', 'BFA CET'].map((item, i) => (
                        <div className="col-6 col-md-3 text-center mb-3" key={i}>
                            <div className="shadow p-3 rounded">
                                <h4>{item}</h4>
                                <div>Entrance Exam</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="container-fluid bg-light pb-5 pt-4">
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-12 text-purple text-center mb-4">
                            <div>MEET OUT TOPPERS</div>
                            <h2><span className="font-300">OUR</span> <span className="font-700">SUCCESS RESULT</span></h2>
                        </div>
                        <Slider items={new Array(14).fill(0).map((_, i) => ({ src: `/images/results/slide${i + 1}.jpg` }))} />
                        {/* {data.slice(20, 25).map((img, i) => (
                            <div className="col-2">
                                <img src={`/images/results/${img}`} className='img-fluid' />
                            </div>
                        ))} */}
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <img src="/images/why-chooseus-bg.jpg" className="img-fluid" />
                    </div>
                    <div className="col-12 col-md-6 bg-orange text-light p-3 p-md-5">
                        <h2><span className="font-300">WHY CHOOSE</span> <span className="font-700">RUDDHI ?</span></h2>
                        <div className="mb-2">
                            <ul>
                                <li>We have limited number of students in each batch, so that faculty can individually look out to each student.</li>
                                <li>Weekly tests on each topic as well as monthly tests according to exam pattern are conducted.</li>
                                <li>Online facility is available as one can experience a feeling of attending a class live though he is attending online.</li>
                                <li>Regular parent’s meets are conducted to know the child’s performance monthly. Also weekly update of student is provided to parents through telephonic communication.</li>
                                <li>We have highly qualified faculties who takes an effort to understand each and every concept to the students.</li>
                                <li>Our mentors/ we also guide the students and parents about the courses after having an IQ test.</li>
                                <li>Consistency of top rankers from Ruddhi are observed in every examination.</li>
                            </ul>
                        </div>
                        <div className="row">
                            {classes.map((course, i) => (
                                <div className="col-6 col-md-4 mt-3 text-center" key={i}>
                                    <div className="bg-white px-2 py-4">
                                        <img src={course.img} />
                                        <div className="mt-3 text-dark text-uppercase">
                                            <b>{course.name}</b>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-light pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-purple text-center mt-4">
                            <h2><span className="font-300">WHAT OUR</span> <span className="font-700">TOPPERS SAYS</span></h2>
                        </div>

                        <div className="col-12 col-md-9 m-auto mt-md-4">
                            <Slider items={new Array(10).fill(0).map((_, i) => ({ src: `/images/feedbacks/slide${i + 1}.jpg` }))} />
                            {/* <div className="d-flex horizontal-scroll w-100 pb-3">
                                {feedbacks.map((feedback, i) => (
                                    <div className={`${i ? '' : ''} mx-5 p-0 mt-5 pt-5`} key={i}>
                                        <div className="feedback-container">
                                            <div className="feedbackbox p-3 pt-0 pb-4 bg-orange text-light">
                                                <div className="avatar">
                                                    <img src={`/images/feedbacks/${feedback.img}.jpeg`} style={{ borderRadius: '50%'}} className="img-fluid" />
                                                </div>
                                                <div className="content">
                                                    {feedback.comment.split('\n').map((item, i) => <div className="comment" key={i}>{item}</div>)}
                                                </div>
                                                <div className="text-center px-3 px-md-5 info">
                                                    <div className="border-bottom border-light mx-3 pb-2">
                                                        <b>{feedback.name}</b>
                                                    </div>
                                                    <div className="mt-2"><b>{feedback.place}</b></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div> */}
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-12 text-purple text-center mt-4">
                            <h2><span className="font-300">PARENTS</span> <span className="font-700">FEEDBACK</span></h2>
                        </div>

                        <div className="col-12 col-md-9 m-auto mt-md-4">
                            <Slider items={new Array(3).fill(0).map((_, i) => ({ src: `/images/feedbacks/slide${i + 11}.jpg` }))} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-light pt-5 pb-5">
                <div className="row">
                    <div className="col-12 text-purple text-center mt-4">
                        <h2><span className="font-300">NEWS &</span> <span className="font-700">UPDATES</span></h2>
                    </div>
                    <div className="col-12 col-md-10 m-auto mt-md-4">
                        <Slider items={new Array(10).fill(0).map((_, i) => ({ src: `/images/news/NEWS AND UPDATE ${i}.png` }))} />
                    </div>
                </div>
            </div>
            <div className="container-fluid bg-purple">
                <div className="container text-light py-5">
                    <div className="row text-uppercase">
                        <div className="col-auto text-center mb-3">
                            <h1 className="font-700">9+</h1>
                            <div>Years</div>
                        </div>
                        <div className="col-auto text-center m-auto mb-3">
                            <h1 className="font-700">100%</h1>
                            <div>Result</div>
                        </div>
                        <div className="col-auto text-center m-auto mb-3">
                            <h1 className="font-700">800+</h1>
                            <div>Students</div>
                        </div>
                        <div className="col-auto text-center m-auto">
                            <Button color="orange" className="border-light" size="lg" onClick={toggleModal}>I WOULD LIKE TO JOIN</Button>
                        </div>
                    </div>
                </div>
            </div>


            <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalBody>
                    <RegistrationForm />
                </ModalBody>
            </Modal>
        </>
    )
}