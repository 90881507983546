import { Button, FormGroup, Input, Label } from "reactstrap"
import { Slider } from "components/slider";

const slides = ['SLIDER 1.jpg', 'SLIDER 2.jpg', 'SLIDER 3.jpg'];
const classes = [
    { name: 'online class', img: '/images/onine-class-icon.png' },
    { name: 'offline class', img: '/images/offline-class-icon.png' },
    { name: 'study material', img: '/images/study-material-icon.png' },
    { name: 'online test', img: '/images/online-test-icon.png' },
    { name: 'exp, faculty', img: '/images/exp-faculty-icon.png' },
    // { name: 'more practicle', img: '/images/more-practice-icon.png' },
]
const studentsWork = ['1 (10).jpg', '1 (11).jpg', '1 (12).jpg', '1 (13).jpg', '1 (14).jpg', '1 (2).jpg', '1 (20).jpg', '1 (21).jpg', '1 (22).jpg', '1 (23).jpg', '1 (26).jpg', '1 (3).jpg', '1 (30).jpg', '1 (4).jpg', '1 (5).jpg', '1 (6).jpg', '1 (7).jpg', '1 (8).jpg', '1 (9).jpg']
const classroom = ['DSC_0002.jpg', 'DSC_0005.jpg', 'DSC_0006 (2).jpg', 'DSC_0006.jpg', 'DSC_0007.jpg', 'DSC_0013.jpg', 'IMG_0813.jpg', 'IMG_20220302_162518.jpg']
const activities = ['20190827_161804.jpg', '20190827_161816.jpg', '20190827_173204.jpg', '20190828_104835.jpg', '20190828_104840.jpg', '20190828_120645.jpg', '20190828_144844.jpg', '20211119_152321.jpg', '20220107_161241.jpg', '20220107_161621.jpg', 'IMG_3662.heic_page-0001.jpg', 'IMG_3686.heic_page-0001.jpg', 'IMG_3708.HEIC_page-0001.jpg', 'IMG_3714.heic_page-0001 (1).jpg', 'Untitled-1.jpg', 'Untitled-2.jpg', 'Untitled-3.jpg']

export const AboutUs = () => {
    return (<>
        <Slider items={slides.map(i => ({ src: '/images/about/' + i }))} />
        <div className="container">
            <div className="row text-center my-5 ">
                <div className="col-12 text-purple text-center mb-4">
                    <h2><span className="font-700">ABOUT US</span></h2>
                </div>
                <div>
                    “RUDDHI” means growth! Fulfilling the real meaning of our name, we are progressing every year by achieving success in our endeavor of Guiding Architecture & Design Aspirants.<br />
                    We are a team of architects, designers and counselors who have been instrumental in shaping the careers of thousands of students in the creative field of architecture of architecture, design and fine arts since 2014.<br />
                    Our unique teaching methodology and rigorous preparation imparts in-depth knowledge and help students understand the concepts thoroughly. Various workshops, seminars, site visits provide essential exposure of the creative field of students. Also our approach of limited number of students (max. 20) in each batch enables us to understand study materials, regular online/classroom tests and dedicated guidance by our experts ensure students to achieve the maximum success.<br />
                    We at RUDDHI, try and maintain the highest standards of infrastructure along with continuously updating ourselves to provide advanced and innovative techniques for the better performance of our students to shape their career.<br />
                </div>
                <div className="col-12 text-purple text-center mb-4 mt-5">
                    <h2><span className="font-700">HIGHLIGHTS</span></h2>
                </div>
                <div className="col-12">
                    <div className="row">
                        {classes.map((course, i) => (
                            <div className="col-3 col-md-2 mt-3 text-center mx-auto" key={i}>
                                <div className="bg-white px-2 py-4">
                                    <img src={course.img} />
                                    <div className="mt-3 text-dark text-uppercase">
                                        <b>{course.name}</b>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid bg-orange text-light">
            <div className="row">
                <div className="col-12 col-md-4 p-3">
                    <img src="/images/about/FACULTY PHOTOS/_MG_7712 (1).jpg" className="img-fluid" />
                    <div className="text-center mt-3">
                        <h5 className="mb-0"><b>AR. SAMPADA TAKALKAR</b></h5>
                        <div className="small-font">B.ARCH, VNIT NAGPUR</div>
                        <div className="small-font">(Director)</div>
                    </div>
                </div>
                <div className="col-12 col-md p-3 ps-0">
                    Design, today is everywhere. It's driving businesses, cultures, media and technology and making sure that the environments we live in (virtual or real) are easier to navigate. Design needs creativity. We at RUDDHI, very keenly nurture the creativity every child is inherently gifted with.<br />
                    Swami Vivekananda once quoted <i>“We want that education by which character is formed, strength of mind is increased, the intellect is expanded, and by which one can stand on one’s own feet.”</i><br />
                    Inspired by the quote, RUDDHI was founded with the aim to guide the students not only in pursuing their interest in the creative field but also help them shape their career in the field of Design/Architecture. Since its inception in 2014, RUDDHI has established itself as an unsurpassed institute providing the best results. Institute's curriculum is an absolute blend of academic and applied knowledge which allows the students to develop the skills and knowledge relevant to their own career paths. Education is imparted through regular theory sessions, workshops and studios, where constant hands-on learning and experimentation happens in an interactive teaching-learning environment.<br />
                    The institute has earned repute as a center for quality education because of its prime infrastructure blended with advanced teaching methods and a disciplinary environment. Our team of academically strong and committed faculty is the backbone of RUDDHI. A special mention about our Alumni, who are now studying in various prestigious National Level Institutes and displaying outstanding performance in their academics as well as in their profession. Our alumni share a unique bond with us and all our students and are always happy to guide the students.  They are a continuous source of inspiration for our regular students.<br />
                    We at RUDDHI, warmly welcome you to explore a wonderful world of creative life and a bright future.<br />
                </div>
            </div>
        </div>

        {/* <div className="container my-5">
            <div className="row">
                {classroom.map(item => (
                    <div className="col-4  mb-5 p-0">
                        <img className="img-fluid" src={`/images/about/Classroom PHOTOS/${item}`} />
                    </div>
                ))}
            </div>
        </div> */}

        <div className="container mb-5">
            <div className="row text-center my-5">
                <div className="col-12 text-purple text-center mb-4">
                    <h2><span className="font-700">STUDENT'S WORK</span></h2>
                </div>
            </div>
            <Slider items={new Array(6).fill(0).map((_, i) => ({ src: `/images/about/student work/slide${i+1}.jpg` }))} />

            <div className="row text-center my-5">
                <div className="col-12 text-purple text-center mb-4">
                    <h2><span className="font-700">ACTIVITES</span></h2>
                    <div className="mt-2">
                        We at Ruddhi not only focus on academic curriculum but also on overall growth on creative thinking and personality development. We conduct multiple activities such as workshops on various art and craft guided by the experts. Our frequent live sketching sessions at different places in and around the city encourages them to develop observation skills. We also arrange study tours to visit reputed design and architecture institutions in the country.
                    </div>
                </div>
            </div>
            <Slider items={new Array(5).fill(0).map((_, i) => ({ src: `/images/about/ACTIVITIES/slide${i+1}.jpg` }))} />

            <div className="row text-center my-5">
                <div className="col-12 text-purple text-center mb-4">
                    <h2><span className="font-700">CLASSROOM PHOTOS</span></h2>
                    <div className="mt-2">
                        Our high standard infrastructure and friendly environment in the classroom provides better learning experience. Our classrooms are equipped for offline as well as online coaching. We also maintain 20 students in every batch to ensure personal interactions and better assessment. Our Students experience comprehensive learning with the help of our Audio Visual room.
                    </div>
                </div>
            </div>
            <Slider items={new Array(2).fill(0).map((_, i) => ({ src: `/images/about/Classroom PHOTOS/slide${i+1}.jpg` }))} />
        </div>
    </>)
}