import React from "react"
import { Button, Input, Label } from "reactstrap"
import { Form, Field } from "react-final-form"
import { sendMail, Parsers, Validators } from "utils"

export const TakeTest = () => {
    const handleSubmit = state => {
        const data = {
            to: process.env.REACT_APP_MAILER_EMAIL,
            subject: 'New Signup Received',
            html: `<div>
            <div>New Signup Received</div><br />
            <div><b style="margin-right: 10px">Full Name:</b> <span>${state.name}</span></div>
            <div><b style="margin-right: 10px">Standard:</b> <span>${state.standard}</span></div>
            <div><b style="margin-right: 10px">Stream:</b> <span>${state.stream}</span></div>
            <div><b style="margin-right: 10px">Course:</b> <span>${state.course}</span></div>
            <div><b style="margin-right: 10px">Exam:</b> <span>${state.exam}</span></div>
            <div><b style="margin-right: 10px">Mobile Number:</b> <span>${state.mobile}</span></div>
            <div><b style="margin-right: 10px">Email Id:</b> <span>${state.email}</span></div>
            </div>`
        }
        sendMail(data, () => {
            alert('Form submitted successfully!')
        })
    }
    return (<>
        <div className="container-fluid bg-light">
            <div className="container">
                <div className="row">
                    <div className="col-12 py-5">
                        <h2 className="text-center text-purple">ENROLL TO GIVE A FREE ONLINE MOCK TEST </h2>
                        <div className="bg-white border p-3 mt-3">
                            <Form onSubmit={handleSubmit} initialValues={{}} render={({ handleSubmit, hasValidationErrors }) => (
                                <form className="row" onSubmit={handleSubmit}>
                                    <Field name="name">
                                        {({ input }) => (
                                            <div className="col-12 col-md-6 mb-3">
                                                <Label>Your Name</Label>
                                                <Input {...input} />
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="standard">
                                        {({ input }) => (
                                            <div className="col-12 col-md-6 mb-3">
                                                <Label>Which Std. Are You Studying In?</Label>
                                                <Input {...input} type="select" >
                                                    <option disabled value=''>Select</option>
                                                    <option>IX Or below</option>
                                                    <option>X STD</option>
                                                    <option>XI STD</option>
                                                    <option>XII STD</option>
                                                    <option>XII PASS</option>
                                                    <option>GRADUATING</option>
                                                    <option>GRADUATED</option>
                                                </Input>
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="stream">
                                        {({ input }) => (
                                            <div className="col-12 col-md-6 mb-3">
                                                <Label>Which Stream have you choosen for XI & XII Std.?</Label>
                                                <Input {...input} type="select" >
                                                    <option disabled value=''>Select</option>
                                                    <option>Science</option>
                                                    <option>Commerce</option>
                                                    <option>Arts</option>
                                                    <option>Not decided</option>
                                                </Input>
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="course">
                                        {({ input }) => (
                                            <div className="col-12 col-md-6 mb-3">
                                                <Label>Which course are you planning to take admission to?</Label>
                                                <Input {...input} type="select" >
                                                    <option disabled value=''>Select</option>
                                                    <option>Design</option>
                                                    <option>Architecture</option>
                                                    <option>Fine Arts</option>
                                                    <option>Not decided</option>
                                                </Input>
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="exam">
                                        {({ input }) => (
                                            <div className="col-12 col-md-6 mb-3">
                                                <Label>Which entrance exam are you planning to attempt?</Label>
                                                <Input {...input} type="select" >
                                                    <option disabled value=''>Select</option>
                                                    <option>NID</option>
                                                    <option>NIFT</option>
                                                    <option>UCEED</option>
                                                    <option>JEE PAPER II</option>
                                                    <option>NATA</option>
                                                    <option>CEED</option>
                                                    <option>Not decided</option>
                                                </Input>
                                            </div>
                                        )}
                                    </Field>

                                    <Field name="mobile" parse={Parsers.number}>
                                        {({ input }) => (
                                            <div className="col-12 col-md-6 mb-3">
                                                <Label>Your Mobile No.</Label>
                                                <Input {...input} maxLength={10} />
                                            </div>
                                        )}
                                    </Field>

                                    <Field name="email">
                                        {({ input }) => (
                                            <div className="col-12 col-md-6 mb-3">
                                                <Label>Email ID</Label>
                                                <Input {...input} />
                                            </div>
                                        )}
                                    </Field>
                                    <div className="col-12 mt-3">
                                        <Button color="orange" type="submit" disabled={hasValidationErrors}>SUBMIT & PROCEED FOR TEST!</Button>
                                    </div>
                                </form>
                            )} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
