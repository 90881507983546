import React from "react"
import { Button, Table } from "reactstrap"
import { Link } from "react-router-dom"
import { RegistrationForm } from "components/registration-form"

export const Courses = () => {
    return (<>
        <div className="container-fluid bg-building bg-light pb-4 pt-5">
            <div className="container mt-4 mb-5">
                <div className="row">
                    <div className="col-12 text-purple text-center mb-4">
                        <div className="text-center">WE PREPARE STUDENTS FOR THE UNDERGRADUATE LEVEL ENTRANCE EXAMS OF ARCHITECTURE, DESIGN AND FINE ARTS</div>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div className="bg-white p-3 text-center rounded h-100 d-flex flex-column">
                            <div className="text-center">
                                <img src='/images/architecture-iocn.png' height={120} />
                            </div>
                            <div className="mt-3">
                                <h3 className="font-700 mb-0">ARCHITECTURE</h3>
                                <h3 className="font-300">ENTRANCE</h3>
                            </div>
                            <div className="mt-3">We prepare students for Architecture entrance exams such as JEE Paper II and NATA. Through these 2 exams, students can get admission to Bachelor of Architecture course to various government and private institutes respectively.</div>
                            <Link to='/courses/ARCHITECTURE' className="mt-auto">
                                <Button color="orange" className="mt-3" >VIEW DETAILS</Button>
                            </Link>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div className="bg-orange p-3 text-center rounded h-100 d-flex flex-column">
                            <div className="text-center">
                                <img src='/images/design-icon.png' height={120} />
                            </div>
                            <div className="mt-3">
                                <h3 className="font-700 mb-0">DESIGN</h3>
                                <h3 className="font-300">ENTRANCE</h3>
                            </div>
                            <div className="mt-3">Various design entrance exams offers admission to different government as well as private institutes all over the country. We prepare students for NID, NIFT, UCEED, UID as well as many design entrance exams. </div>
                            <Link to='/courses/DESIGN' className="mt-auto">
                                <Button color="purple" className="mt-3 border-white" >VIEW DETAILS</Button>
                            </Link>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <div className="bg-white p-3 text-center rounded h-100 d-flex flex-column">
                            <div className="text-center">
                                <img src='/images/fineart-icon.png' height={120} />
                            </div>
                            <div className="mt-3">
                                <h3 className="font-700 mb-0">FINE ARTS</h3>
                                <h3 className="font-300">ENTRANCE</h3>
                            </div>
                            <div className="mt-3">We prepare students for State level entrance exam MH-AACET for admission to fine arts colleges. </div>
                            <Link to='/courses/FINE ARTS' className="mt-auto">
                                <Button color="orange" className="mt-3" >VIEW DETAILS</Button>
                            </Link>
                        </div>
                    </div>

                    <div className="col-12 mt-4">
                        <h2 className="text-center text-purple">BUILD YOUR DREAM CAREER WITH US</h2>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="bg-white rounded p-3">
                            <h3 className="text-purple">Features</h3>
                            <ol>
                                <li>MONTHLY TESTS</li>
                                <li>WEEKLY ASSESMENT</li>
                                <li>YEARLY COLLEGE VISIT</li>
                                <li>REGULAR LIVE SKETCHING SESSIONS</li>
                                <li>TOPIC WISE TESTS</li>
                                <li>EXAM PATTERN ORIENTED PRELIMS</li>
                                <li>REGULAR DOUBT SESSIONS</li>
                                <li>INDIVIDUAL ATTENSION AND REVIEW ON PROGRESS</li>
                            </ol>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <RegistrationForm />
                    </div>
                </div>
            </div>
        </div>
    </>)
}


export const Course = ({ match }) => {
    const name = match.params.course
    return (<>
        <div className="container-fluid bg-building bg-light pb-4 pt-5">
            <div className="container mt-4 mb-5">
                <div className="row">
                    <div className="col-12">
                        <h3 className="text-center text-purple">CLASS XI & XII {name}</h3>
                        <Table striped bordered>
                            {name == 'DESIGN' ? (
                                <tbody>
                                    <tr>
                                        <th>ELIGIBILITY</th>
                                        <td>11TH AND 12TH CLASS STUDENT PURSUING ARTS/COMMERCE/ SCIENCE STREAM </td>
                                    </tr>
                                    <tr>
                                        <th>SYLLABUS COVERED</th>
                                        <td>GK, APTITUDE, MATHS & DRAWING </td>
                                    </tr>
                                    <tr>
                                        <th>IDEAL FOR</th>
                                        <td>NID, NIFT, UCEED AND OTHER DESIGN INSTITUTE ENTRANCE EXAM</td>
                                    </tr>
                                    <tr>
                                        <th>TOTAL COURSE DURATION</th>
                                        <td>2 YEARS: 100 WEEKS ( TOTAL 300 CLASSES) OR 1 YEAR: 52 WEEKS: ( TOTAL 150 CLASSES)</td>
                                    </tr>
                                    <tr>
                                        <th>CLASSES PER WEEK</th>
                                        <td>3 CLASSES  (ALTERNATE DAYS)</td>
                                    </tr>
                                    <tr>
                                        <th>FEES</th>
                                        <td>  
                                            <Link to='/contact-us'>                          
                                                <Button className="me-3" color="orange">ENQUIRE NOW</Button>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>STUDY MATERIAL</th>
                                        <td>
                                            <ul>
                                                <li>TOPIC WISE WORKSHEETS</li>
                                                <li>GK AND APTITUDE NOTES</li>
                                                <li>PREVIOUS YEAR SOLVED PAPERS</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : name == 'ARCHITECTURE' ? (
                                <tbody>
                                    <tr>
                                        <th>ELIGIBILITY</th>
                                        <td>11TH AND 12TH CLASS STUDENT SCIENCE STREAM  </td>
                                    </tr>
                                    <tr>
                                        <th>SYLLABUS COVERED</th>
                                        <td>GK, APTITUDE, MATHS & DRAWING</td>
                                    </tr>
                                    <tr>
                                        <th>IDEAL FOR</th>
                                        <td>NID, NIFT, UCEED AND OTHER DESIGN INSTITUTE ENTRANCE EXAM</td>
                                    </tr>
                                    <tr>
                                        <th>TOTAL COURSE DURATION</th>
                                        <td>2 YEARS: 100 WEEKS ( TOTAL 300 CLASSES) OR 1 YEAR: 52 WEEKS: ( TOTAL 150 CLASSES)</td>
                                    </tr>
                                    <tr>
                                        <th>CLASSES PER WEEK</th>
                                        <td>3 CLASSES  (ALTERNATE DAYS)</td>
                                    </tr>
                                    <tr>
                                        <th>FEES</th>
                                        <td>
                                            <Link to='/contact-us'>                          
                                                <Button className="me-3" color="orange">ENQUIRE NOW</Button>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>STUDY MATERIAL</th>
                                        <td>
                                            <ul>
                                                <li>TOPIC WISE WORKSHEETS</li>
                                                <li>GK AND APTITUDE NOTES</li>
                                                <li>PREVIOUS YEAR SOLVED PAPERS</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <th>ELIGIBILITY</th>
                                        <td>11TH AND 12TH CLASS STUDENT PURSUING ARTS/COMMERCE/ SCIENCE STREAM </td>
                                    </tr>
                                    <tr>
                                        <th>SYLLABUS COVERED</th>
                                        <td>GK, APTITUDE, MATHS & DRAWING </td>
                                    </tr>
                                    <tr>
                                        <th>IDEAL FOR</th>
                                        <td>NID, NIFT, UCEED AND OTHER DESIGN INSTITUTE ENTRANCE EXAM</td>
                                    </tr>
                                    <tr>
                                        <th>TOTAL COURSE DURATION</th>
                                        <td>2 YEARS: 100 WEEKS ( TOTAL 300 CLASSES) OR 1 YEAR: 52 WEEKS: ( TOTAL 150 CLASSES)</td>
                                    </tr>
                                    <tr>
                                        <th>CLASSES PER WEEK</th>
                                        <td>3 CLASSES  (ALTERNATE DAYS)</td>
                                    </tr>
                                    <tr>
                                        <th>FEES</th>
                                        <td>
                                            <Link to='/contact-us'>                          
                                                <Button className="me-3" color="orange">ENQUIRE NOW</Button>
                                            </Link>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>STUDY MATERIAL</th>
                                        <td>
                                            <ul>
                                                <li>TOPIC WISE WORKSHEETS</li>
                                                <li>GK AND APTITUDE NOTES</li>
                                                <li>PREVIOUS YEAR SOLVED PAPERS</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </Table>
                        <div className="mt-3 text-center">
                            <Button className="me-3" color="orange" onClick={() => window.history.back()}>BACK</Button>
                            <a href="http://ruddhi.co.in/registration/" target='_blank'><Button color="orange">ENROLL NOW</Button></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}