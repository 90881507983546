import { useState } from 'react'
import { Button, FormGroup, Input, Label } from "reactstrap"
import { sendMail } from 'utils'

export const ContactUs = () => {
    const [state, setState] = useState({})

    const handleChange = e => {
        let { name, value } = e.target
        if (name == 'mobile') value = value.replace(/[\D]/g, '')
        setState({ ...state, [name]: value })
    }
    const handleSubmit = () => {
        const data = {
            to: process.env.REACT_APP_MAILER_EMAIL,
            subject: 'New Query Received',
            html: `<div>
            <div>New Query Received</div><br />
            <div><b style="margin-right: 10px">Full Name:</b> <span>${state.name}</span></div>
            <div><b style="margin-right: 10px">Mobile Number:</b> <span>${state.mobile}</span></div>
            <div><b style="margin-right: 10px">Email Id:</b> <span>${state.email}</span></div>
            <div><b style="margin-right: 10px">Query:</b> <span>${state.query}</span></div>
            </div>`
        }
        sendMail(data, () => {
            setState({})
            alert('Message sent successfully!')
        })
    }

    return (<>
        <div className="container-fluid bg-orange">
            <div className="container">
                <div className="row">
                    <div className="col-auto align-self-center justify-self-center ms-md-5 order-md-last mt-5 mt-md-0">
                        <h2 className="font-800 text-light">Glad to meet you!</h2>
                    </div>
                    <div className="col-auto pt-5">
                        <img className="img-fluid" src="/images/contactus-page-header-infographic.png" />
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row text-center my-5">
                <div className="col-12 col-md-4">
                    <img src="/images/address-infographic.png" />
                    <div className="mt-3"><b>Ruddhi Eduserv</b></div>
                    <div className="mt-3">
                        <div><a href='https://goo.gl/maps/nXG4R9umWRk5cD2S9' target="_blank"><b className='text-dark'>Aurangabad branch</b> <span className='fa fa-map-marker text-danger'></span></a></div>
                        Ruddhi Eduserv LLP 10, Vyankatesh Colony,
                        Opposite Lokvikas Nagari Bank, Agnihotra Chowk
                        Near Chetak Ghoda, Aurangbad-431005
                    </div>
                    <div className="mt-3">
                        <div><a target="_blank" href='https://maps.app.goo.gl/UmykVZ8cozW4pbH89'><b className='text-dark'>Pune branch</b> <span className='fa fa-map-marker text-danger'></span></a></div>
                        Ruddhi Eduserv LLP<br />
                        Flat no. 7, Indraprastha, Next to Amber Hall, Near Karishama Society Chowk, Mayur Colony, Kothrud Pune-411029
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <img src="/images/mobile-infographic.png" />
                    <div className="mt-3"><b>Mobile Number</b></div>
                    <div className="mt-3">
                        <div><b>Aurangabad branch</b></div>
                        <div>+91-8237016547</div>
                        <div>+91-8446017141</div>
                        <div>(Working hours : 10:00 AM to 06:00 PM)</div>
                        <div className="mt-3"><b>Pune branch</b></div>
                        <div>+91-9209938326</div>
                        <div>(Working hours : 10:00 AM to 06:00 PM)</div>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <img src="/images/email-infographic.png" />
                    <div className="mt-3"><b>Email ID</b></div>
                    <div className="mt-3">
                        <div><a href="mailto:ruddhieduservllp@gmail.com" className="text-dark">ruddhieduservllp@gmail.com</a></div>
                        <div><a href="mailto:Ruddhi.academy@gmail.com" className="text-dark">Ruddhi.academy@gmail.com</a></div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-fluid bg-light">
            <div className="container py-5">
                <h2 className="text-center"><b>SEND A MESSAGE</b></h2>
                <div className="row mt-3">
                    <div className="col-12 col-md-6">
                        <FormGroup>
                            <Label>Full Name*</Label>
                            <Input type="text" name="name" value={state.name || ''} onChange={handleChange} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Mobile Number*</Label>
                            <Input type="text" name="mobile" value={state.mobile || ''} onChange={handleChange} maxLength={10} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Email ID</Label>
                            <Input type="text" name="email" value={state.email || ''} onChange={handleChange} />
                        </FormGroup>
                    </div>
                    <div className="col-12 col-md-6 d-flex flex-column">
                        <FormGroup>
                            <Label>Enter your query</Label>
                            <Input type="textarea" rows={4} name='query' value={state.query || ''} onChange={handleChange} />
                        </FormGroup>
                        <Button color="orange" className="mt-3 mb-3 w-auto" onClick={handleSubmit}>SUBMIT</Button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}