import { Route, Switch } from "react-router-dom"
import { Home } from "pages/home"
import { FAQ } from "pages/faq"
import { ContactUs } from "pages/contactus"
import { TestSeries } from "pages/testSeries"
import { Careers, Career } from "pages/careers"
import { TakeTest } from "pages/takeTest"
import { Courses, Course } from "pages/courses"
import { AboutUs } from "pages/aboutUs"
import { LiveClass } from "pages/liveClass"
import { Terms } from "pages/terms"
import { Privacy } from "pages/privacy"
import { Results } from "pages/results"

export const AppRouter = () => {
    return (
        <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/about-us' component={AboutUs} />
            <Route path='/faq' component={FAQ} />
            <Route path='/contact-us' component={ContactUs} />
            <Route exact path='/test-series' component={TestSeries} />
            <Route exact path='/career' component={Careers} />
            <Route path='/career/:name' component={Career} />
            <Route path='/take-test' component={TakeTest} />
            <Route path='/live-class' component={LiveClass} />
            <Route exact path='/courses' component={Courses} />
            <Route path='/courses/:course' component={Course} />
            <Route path='/terms&conditions' component={Terms} />
            <Route path='/privacy&policy' component={Privacy} />
            <Route path='/result' component={Results} />
        </Switch>
    )
}