import React from "react"

const faqs = [
    {
        question: 'Which are the institutions/colleges where one can get admission?',
        answer: "Coaching is offered for JEE (II paper), NATA, NID Entrance, NIFT & OTHER Design institute Entrance Exams.  Depending on the performance in these entrance exams & the rank obtained, students get admission to (5 years degree course) B.Arch. in SPAs, NIT's and other reputed college of Architecture. In Design stream (B. Des. 4 year’s degree) in institutions like NID, NIFT, UID, MIT-Pune, etc. and other design colleges/institutes."
    },
    {
        question: 'What is the duration of Coaching?',
        answer: 'The duration of coaching at Ruddhi is 1 full calendar year (300 days), Students should join in XIth standard preferably for best and comfortable completion of coaching spread over two academic years.'
    },
    {
        question: 'Is there any facility of crash course batch? What is the maximum duration of such coaching?',
        answer: 'Crash course is conducted for minimum of 6 months duration, which will be more than 2 hrs. Daily depending on the grasping of the student/candidate.'
    },
    {
        question: 'Is the Coaching online or offline?',
        answer: 'The coaching is online for the students in cities except the places where class room coaching is available.'
    },
    {
        question: 'For how many days in a week the coaching is?',
        answer: 'The coaching is two hours every alternate day for two years. For students joining late or in XIIth standard the coaching will be 6 days in a week for two hours daily.'
    },
    {
        question: 'Can personal Guidance be sought during the class?',
        answer: 'All topics are explained and taught in the class to all the students.'
    },
    {
        question: 'Is any study material is provided during the coaching?',
        answer: 'For Architecture students special study material in the form of our own book will be provided in the second half of XIIth standard. The design students will get the study material mainly for theory topics in the mid of the XIIth standard. Apart from this, few reference books and study material is available in our class for reference throughout the year.'
    },
    {
        question: 'Are test & exams conducted?',
        answer: 'Monthly class tests are conducted on the topics covered during the month. Apart from which practice tests are conducted from time to time. 5 preliminary tests are conducted before the actual Examinations from the Month of Jan. to March. every year.'
    },
    {
        question: 'Who is eligible for getting admission in the coaching class?',
        answer: 'Candidates who either have appeared or passed in SSC/10th standard. With minimum at 50% marks are eligible for admission in Ruddhi. For Design entrance candidates of arts, commerce and science are eligible but for Architecture, students of science faculty are eligible.'
    }
]

export const FAQ = () => {
    return (<>
        <div className="container-fluid bg-orange">
            <div className="container">
                <div className="row">
                    <div className="col-auto align-self-center justify-self-center ms-md-5 order-md-last mt-5 mt-md-0">
                        <h2 className="font-800 text-light">Hi! How can we help you?</h2>
                    </div>
                    <div className="col-auto pt-5">
                        <img className="img-fluid" src="/images/faq-page-header-infographic.png" />
                    </div>
                </div>
            </div>
        </div>
        <div className="container my-5">
            <div className="row">
                <div className="col-12">
                    {faqs.map((faq, i) => (
                        <div className="border mb-3" key={i}>
                            <div className="bg-purple p-3 font-500 text-light">{i + 1}. {faq.question}</div>
                            <div className="p-3">{faq.answer}</div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </>)
}