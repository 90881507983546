const required = (val) => {
    if (val?.trim()) return
    else return 'Field is required'
}
const mobile = (val) => {
    if (/[1-9][\d]{9}/.test(val)) return
    else return 'Incorrect mobile number'
}
const compose = (...validators) => (val) => validators.every(validator => validator(val))

export const Validators = {
    required,
    mobile,
    compose
}