import React from 'react'
import { Link } from 'react-router-dom'
import logo from 'assets/images/logo.png'


export const Footer = () => {
    return (
        <div className='container-fluid bg-orange shadow p-3 px-md-5 py-md-5 text-light font-300'>
            <div className='container font-500'>
                <div className='row'>
                    <div className='col-12 col-md-auto m-auto text-center mt-3 mt-md-5 mb-3'>
                        <img src={logo} width='150px' />
                        <div className='mt-4 font-300'>
                            Copyrights:- Ruddhi Eduserv LLP
                        </div>
                    </div>

                    <div className='col-auto text-uppercase mb-3'>
                        <div><b>USEFULL LINKS</b></div>
                        <div>
                            <Link to='/' className='text-light font-300'>Home</Link>
                        </div>
                        <div>
                            <Link to='/about-us' className='text-light font-300'>About Us</Link>
                        </div>
                        <div>
                            <Link to='/contact-us' className='text-light font-300'>Contact Us</Link>
                        </div>
                        <div>
                            <Link to='/help' className='text-light font-300'>Help & Support</Link>
                        </div>
                        <div>
                            <Link to='/affiliate-career' className='text-light font-300'>Affiliate/ Career</Link>
                        </div>
                        <div>
                            <Link to='terms&conditions' className='text-light font-300'>Tearms & Condition</Link>
                        </div>
                        <div>
                            <Link to='/faq' className='text-light font-300'>FAQ'S</Link>
                        </div>
                    </div>

                    <div className='col-auto text-uppercase mb-3'>
                        <div><b>Course</b></div>
                        <div>
                            <Link to='/courses' className='text-light font-300'>NATA</Link>
                        </div>
                        <div>
                            <Link to='/courses' className='text-light font-300'>JEE Paper II</Link>
                        </div>
                        <div>
                            <Link to='/courses' className='text-light font-300'>NID</Link>
                        </div>
                        <div>
                            <Link to='/courses' className='text-light font-300'>NIFT</Link>
                        </div>
                        <div>
                            <Link to='/courses' className='text-light font-300'>UCEED</Link>
                        </div>
                        <div>
                            <Link to='/courses' className='text-light font-300'>CEED</Link>
                        </div>
                        <div>
                            <Link to='/privacy&policy' className='text-light font-300'>PRIVACY POLICY</Link>
                        </div>
                        <div>
                            <Link to='/courses' className='text-light font-300'>BFA EXAM</Link>
                        </div>
                        <div>
                            <Link to='/courses' className='text-light font-300'>MAH CET</Link>
                        </div>
                    </div>

                    <div className='col-12 col-md-4 font-300'>
                        <div><b>GET IN TOUCH</b></div>
                        <div className='font-500'><b>RUDDHI EDUSERV LLP</b></div>
                        <div>
                            Ruddhi Eduserv LLP 10, Vyankatesh Colony,<br />
                            Opposite Lokvikas Nagari Bank<br />
                            Agnihotra Chowk<br />
                            Near Chetak Ghoda, Aurangbad-431005<br /><br/>
                            
                            Ruddhi Eduserv LLP<br />
                            Flat no. 7, Indraprastha, Next to Amber Hall, Near Karishama Society Chowk, Mayur Colony, Kothrud Pune-411029
                            <div className='mt-3'>
                                <span className='fa fa-phone-alt'></span> +91-8237016547, +91-8446017141
                            </div>
                            <div >
                                <a href='mailto:ruddhieduservllp@gmail.com' className='text-light'>
                                    <span className='fa fa-envelope'></span> ruddhieduservllp@gmail.com
                                </a>
                            </div>
                            <div>(working hours : 10:00 AM to 06:00 PM)</div>
                        </div>
                        <div className='d-flex mt-2'>
                            <div className='social-icon'>
                                <a href="https://www.instagram.com/ruddhi_eduserv/" target='_blank' className="text-dark"><span className='fa fa-instagram'></span></a>
                            </div>
                            <div className='social-icon'>
                                <a href="https://www.facebook.com/ruddhi.academy/" target='_blank' className="text-dark"><span className='fa fa-facebook'></span></a>
                            </div>
                            <div className='social-icon'>
                                <a href="https://wa.me/message/YAUCX5RHI6U6D1" target='_blank' className="text-dark"><span className='fa fa-whatsapp'></span></a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}