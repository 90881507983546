import { Home } from "pages/home"
import { Footer } from "./footer"
import { Header } from "./header"
import { AppRouter } from "./router"

export const Layout = () => {
    return (<>
        <Header />
        {/* <Home /> */}
        <AppRouter />
        <Footer />
    </>)
}