export const sendMail = (body, callback) => {
    fetch(process.env.REACT_APP_MAIL_API_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })
            .then(res => {
                if (res.ok) return res.json()
                else throw new Error(res.statusText)
            })
            .then(res => {
                callback()
            })
            .catch(err => null)
}

