import React, { useState } from "react"
import { Button, Input, Label, Spinner } from "reactstrap"
import { Form, Field } from "react-final-form"
import { sendMail, Validators, Parsers } from "utils"

export const RegistrationForm = () => {
    const [state, setState] = useState()

    const handleSubmit = state => {
        setState(true)
        const data = {
            to: process.env.REACT_APP_MAILER_EMAIL,
            subject: 'New Signup Received',
            html: `<div>
            <div>New Signup Received</div><br />
            <div><b style="margin-right: 10px">Full Name:</b> <span>${state.name}</span></div>
            <div><b style="margin-right: 10px">Mobile Number:</b> <span>${state.mobile}</span></div>
            <div><b style="margin-right: 10px">Email Id:</b> <span>${state.email}</span></div>
            <div><b style="margin-right: 10px">City:</b> <span>${state.city}</span></div>
            <div><b style="margin-right: 10px">Standard:</b> <span>${state.standard}</span></div>
            <div><b style="margin-right: 10px">Stream:</b> <span>${state.stream}</span></div>
            <div><b style="margin-right: 10px">Interested Exam:</b> <span>${state.exam}</span></div>
            <div><b style="margin-right: 10px">Query:</b> <span>${state.query}</span></div>
            </div>`
        }
        sendMail(data, () => {
            setState(false)
            alert('Form submitted successfully!')
        })
    }

    return (<>
        <div className="bg-white rounded p-3">
            <h5 className="text-purple">SIGN UP BELOW FOR FREE DEMO CLASS</h5>
            {state ? (<div className="d-flex align-item-center justify-content-center py-5">
                <Spinner size='lg' />
            </div>) : (
                <Form onSubmit={handleSubmit} initialValues={{}} render={({ handleSubmit, hasValidationErrors }) => (
                    <form onSubmit={handleSubmit} className="row">
                        <Field name="name" validate={Validators.required}>
                            {({ input }) => (
                                <div className="col-12 col-md-6 mb-3">
                                    <Label>STUDENTS FULL NAME</Label>
                                    <Input {...input} />
                                </div>
                            )}
                        </Field>
                        <Field name="mobile" validate={Validators.required} parse={Parsers.number}>
                            {({ input }) => (
                                <div className="col-12 col-md-6 mb-3">
                                    <Label>MOBILE NO.</Label>
                                    <Input {...input} maxLength={10} />
                                </div>
                            )}
                        </Field>
                        <Field name="email" validate={Validators.required}>
                            {({ input }) => (
                                <div className="col-12 col-md-6 mb-3">
                                    <Label>EMAIL ID</Label>
                                    <Input {...input} />
                                </div>
                            )}
                        </Field>
                        <Field name="city" validate={Validators.required}>
                            {({ input }) => (
                                <div className="col-12 col-md-6 mb-3">
                                    <Label>CITY</Label>
                                    <Input {...input} />
                                </div>
                            )}
                        </Field>
                        <Field name="standard" validate={Validators.required}>
                            {({ input }) => (
                                <div className="col-12 col-md-6 mb-3">
                                    <Label>STANDARD</Label>
                                    <Input {...input} />
                                </div>
                            )}
                        </Field>
                        <Field name="stream" validate={Validators.required}>
                            {({ input }) => (
                                <div className="col-12 col-md-6 mb-3">
                                    <Label>STREAM</Label>
                                    <Input {...input} />
                                </div>
                            )}
                        </Field>
                        <Field name="exam" validate={Validators.required}>
                            {({ input }) => (
                                <div className="col-12 mb-3">
                                    <Label>WISH TO APPEAR FOR</Label>
                                    <Input {...input} type='select' >
                                        <option value='' disabled>Select</option>
                                        {['Design', 'Arch.', 'Fine Arts (UG / PG)'].map((item, i) => <option key={i} value={item}>{item}</option>)}
                                    </Input>
                                </div>
                            )}
                        </Field>
                        <Field name="query">
                            {({ input }) => (
                                <div className="col-12 mb-3">
                                    <Label>ANY QUERIES</Label>
                                    <Input {...input} type="textarea" />
                                </div>
                            )}
                        </Field>
                        <div className="col-12">
                            <Button color="orange" type="submit" disabled={hasValidationErrors}>Submit</Button>
                        </div>
                    </form>
                )} />)}
        </div>
    </>)
}