import { useState, useEffect } from "react"
import { NavLink } from "react-router-dom"

const navTabs = [
    { name: 'home', path: '/' },
    { name: 'about us', path: '/about-us' },
    { name: 'courses', path: '/courses' },
    // { name: 'test series', path: '/test-series' },
    { name: 'take test', path: '/take-test' },
    { name: 'result', path: '/result' },
    { name: 'live class', path: '/live-class' },
    { name: 'contact us', path: '/contact-us' },
    { name: 'career', path: '/career' },
    { name: 'FAQ', path: '/faq' },
]

export const Header = () => {
    const [active, setActive] = useState('/')
    const [collapseOpen, setCollapseOpen] = useState(true)

    useEffect(() => {
        setActive(window.location.pathname)
    }, [window.location.pathname])

    const toggleCollapse = () => setCollapseOpen(!collapseOpen)

    return (<>
        <div className="container-fluid bg-orange d-none d-md-block">
            <div className="py-2 text-light d-flex align-items-center">
                <div className="ms-auto d-flex align-items-center">
                    <span className="fa fa-phone-alt me-2"></span>  8237016547, 8446017141
                    <a href="mailto:ruddhieduservllp@gmail.com" target='_blank' className="text-light me-3"><span className="fa fa-envelope ms-4"></span> ruddhieduservllp@gmail.com</a>
                    <a href="https://www.facebook.com/ruddhieduserv/" target='_blank' className="text-light"><span className="fa fa-facebook-f fa-2x me-3"></span></a>
                    <a href="https://www.instagram.com/ruddhieduserv/" target='_blank' className="text-light"><span className="fa fa-instagram fa-2x me-3"></span></a>
                    <a href="https://wa.me/message/YAUCX5RHI6U6D1" target='_blank' className="text-light"><span className="fa fa-whatsapp fa-2x me-3"></span></a>
                    <a href="http://ruddhi.co.in/webmail" target='_blank' className="btn btn-purple">LOGIN</a>
                </div>
            </div>
        </div>
        <div className="container-fluid bg-white shadow">
            <div className="d-flex align-items-center ">
                <div>
                    <img src='/images/ruddhi-header-logo.png' height={60} />
                </div>
                <div className="ms-auto d-flex d-md-none text-purple">
                    <span className="fa fa-bars fa-2x" onClick={toggleCollapse}></span>
                </div>
                <div className={`d-md-none navbar-collapse p-3 bg-orange ${collapseOpen ? 'show' : ''}`}>
                    <div className="offcanvas-header">
                        <span className="fa fa-times fa-2x" onClick={toggleCollapse}></span>
                    </div>
                    <div>
                        {navTabs.map((tab, i) => (
                            <div key={i} className='mt-2'>
                                <NavLink to={tab.path} onClick={() => setActive(tab.path)} className={`text-uppercase px-1 mx-2 py-1 ${active == tab.path ? 'text-light border-bottom border-2 border-light' : 'text-dark'}`}>
                                    <span className="font-500">{tab.name}</span> <span></span>
                                </NavLink>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="ms-auto h-100 d-none d-md-flex">
                    {navTabs.map((tab, i) => (
                        <NavLink to={tab.path} key={i} onClick={() => setActive(tab.path)} className={`text-uppercase px-1 mx-2 py-3 ${active == tab.path ? 'text-purple border-bottom border-2 border-purple' : 'text-dark'}`}>
                            <span className="font-500">{tab.name}</span> <span></span>
                        </NavLink>
                    ))}
                </div>
            </div>
        </div>
    </>)
}